import React, { useState,useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "react-bootstrap/Spinner";
import "../../styles/Table.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocationModal from "../Modals/LocationModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "react-bootstrap/Modal";
import DatePicerCom from "../datacomponent/DatePicker";
import Button from "react-bootstrap/Button";
import { getDatabase, ref, child, get, update,remove } from "firebase/database";
import { app } from "../../Firbase";
import { getAuth } from "firebase/auth";
import HistoryIcon from '@mui/icons-material/History';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import Switch from "react-switch";
import { Link } from 'react-router-dom';
const UnLockedCustomerTable = ({
  data = [],
  loading,
  onToggleChange,
  onDeleteUser,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [localLoading, setLocalLoading] = useState({});
  const [showLocationModal, setShowLocationModal] = useState(false);




  const [selectedLocation, setSelectedLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [User, setUser] = useState({});
    
 
  const db = getDatabase(app);
  const dbRef = ref(db);

  const auth = getAuth(app);
  const currentUser = auth.currentUser;
 
  // const uid = currentUser ? currentUser.uid : null;

useEffect(() => {
  const getCurrentUser = async () => {
    if (currentUser.uid) {
     
      try {
        const snapshot = await get(child(dbRef, `users/admin/${currentUser.uid}`));
        if (snapshot.exists()) {
          const userData = snapshot.val();
         
          setUser(userData);
        } else {
          console.log("No data available for the current user");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    } else {
      console.log("No user is currently logged in");
    }
  };

  getCurrentUser();
}, [currentUser.uid]);





  const handleToggleChange = async (parentUid, phone, currentLockedStatus) => {
    const updatedStatus = !currentLockedStatus;

    // Set local loading state for the specific record
    setLocalLoading((prev) => ({ ...prev, [`${parentUid}${phone}`]: true }));

    try {
      await onToggleChange(parentUid, phone, updatedStatus);
      toast.success("Status updated successfully!");
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error(" this user status or not update  ");
    } finally {
      // Remove loading state for the specific record
      setLocalLoading((prev) => ({ ...prev, [`${parentUid}${phone}`]: false }));
    }
  };

  const handleShowDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      try {
        await onDeleteUser(userToDelete.parentUid, userToDelete.phone);
        toast.success("User deleted successfully!");
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error("This user could not be deleted.");
      } finally {
        setShowDeleteModal(false);
        setUserToDelete(null);
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  const handleShowLocation = (latitude, longitude) => {
    setSelectedLocation({ latitude, longitude });
    setShowLocationModal(true);
  };

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };

  // Filter users based on search term
  const filteredUsers = data.filter(
    (user) =>
      (user.name &&
        user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.phone &&
        user.phone.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.parentPhone &&
        user.parentPhone.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.mobiletype &&
        user.mobiletype.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.mobileimei &&
        user.mobileimei.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="mt-2">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-end">
      {User && User?.category !== "sub admin" && (
      <DatePicerCom/>
      )}
        <div className="my-3 border border-gray d-flex align-items-center px-2 py-2 rounded w-25">
          <input
            style={{ outline: "none", border: "none", width: "100%" }}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SearchIcon style={{ color: "#888" }} />
        </div>
      </div>
      <div className="table-container">
  <table className="table table-striped">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Phone</th>
        <th scope="col">Parent Phone</th>
        <th scope="col">Mobile Type</th>
        <th scope="col">Mobile IMEI</th>
        <th scope="col">Status</th>
        <th scope="col">Location</th>
        <th scope="col">Action</th>
        <th scope="col">History</th>
      </tr>
    </thead>
    <tbody>
      {loading ? (
        <tr>
          <td colSpan="9" style={{ textAlign: "center", padding: "20px" }}>
            <Spinner animation="grow" variant="dark" />
          </td>
        </tr>
      ) : filteredUsers.length === 0 ? (
        <tr>
          <td colSpan="9" style={{ textAlign: "center", padding: "20px" }}>
            No data found
          </td>
        </tr>
      ) : (
        filteredUsers.map((user, index) => (
          <tr key={index}>
            <td>{user.name || "NA"}</td>
            <td>{user.phone}</td>
            <td>{user.parentPhone}</td>
            <td>{user.mobiletype}</td>
            <td>{user.mobileimei}</td>
            <td>
              {user.kidstatus ? (
                <>
                  Active
                  <FiberManualRecordIcon style={{ color: "green", fontSize: "18px" }} />
                </>
              ) : (
                <>
                  Inactive
                  <FiberManualRecordIcon style={{ color: "red", fontSize: "18px" }} />
                </>
              )}
            </td>
            <td>
              {user.location ? (
                <button
                  className="btn btn-link"
                  onClick={() => handleShowLocation(user.location.latitude, user.location.longitude)}
                >
                  <LocationOnIcon style={{ color: "green" }} />
                </button>
              ) : (
                <button className="btn btn-link">
                  <LocationOffIcon style={{ color: "gray" }} />
                </button>
              )}
            </td>
          
              <td>
                <div className="toggle-container d-flex align-items-center gap-2">
                  <Switch
                    onChange={() => handleToggleChange(user.parentUid, user.phone, user.screenLock?.locked || false)}
                    checked={user.screenLock?.locked || false}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#00c853"
                    offColor="#ff1744"
                    disabled={localLoading[`${user.parentUid}${user.phone}`]}
                  />
                    {User && User?.category !== "sub admin" && (
                  <button
                    className="btn btn-danger ml-2"
                    onClick={() => handleShowDeleteModal(user)}
                    disabled={localLoading[`${user.parentUid}${user.phone}`]}
                  >
                    <DeleteIcon />
                  </button>
                   )}
                </div>
              </td>
           
            <td>
              <Link to={`/callhistry?parentUid=${user.parentUid}&phone=${user.childnumber}`} className="btn-link">
                <HistoryIcon style={{ color: "green" }} />
              </Link>
            </td>
          </tr>
        ))
      )}
    </tbody>
  </table>
</div>
      <LocationModal
        show={showLocationModal}
        handleClose={handleCloseLocationModal}
        latitude={selectedLocation.latitude}
        longitude={selectedLocation.longitude}
      />

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UnLockedCustomerTable;
