import React, { useEffect, useState } from "react";
import {
  getDatabase,
  ref,
  child,
  get,
  update,
  remove,
} from "firebase/database";
import ActiveUserTable from "../../components/Tabals/ActiveUserTable";
import { app } from "../../Firbase";

const Index = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllUsers = async () => {
      const dbRef = ref(getDatabase(app));

      try {
        const snapshot = await get(child(dbRef, "users/childs"));
        if (snapshot.exists()) {
          const allUsers = snapshot.val();
          const filteredUsers = Object.values(allUsers).filter(
            (user) => user.kidstatus === true
          );
          setUsers(filteredUsers);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
      } finally {
        setLoading(false);
      }
    };

    getAllUsers();
  }, []);

  const handleToggleChange = async (parentUid, phone, updatedStatus) => {
    const db = getDatabase();
    const userRef = ref(db, `users/childs/${parentUid}${phone}/screenLock`);

    try {
      await update(userRef, { locked: updatedStatus });
      
      // Update the user state directly
      setUsers(prevUsers =>
        prevUsers.map(user => 
          user.parentUid === parentUid && user.phone === phone 
            ? { ...user, screenLock: { ...user.screenLock, locked: updatedStatus } }
            : user
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleDeleteUser = async (parentUid, phone) => {
    const db = getDatabase();
    const childRef = ref(db, `users/childs/${parentUid}${phone}`);
    const parentKidRef = ref(db, `users/parents/${parentUid}/kids/${parentUid}${phone}`);
   
    try {
      await remove(childRef);  // Remove from users/childs
      await remove(parentKidRef); // Remove from parents/kids
      
      // Remove the deleted user from the state
      setUsers(prevUsers => prevUsers.filter(user => !(user.parentUid === parentUid && user.phone === phone)));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  return (
    <div>
      <div className="mt-3">
        <h5 className="fw-bold">Active Users</h5>
        <ActiveUserTable
          data={users}
          loading={loading}
          onToggleChange={handleToggleChange}
          onDeleteUser={handleDeleteUser}
        />
      </div>
    </div>
  );
};

export default Index;
